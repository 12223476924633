import Header from './components/Header';
import Footer from './components/Footer';
import AboutPage from './pages/AboutPage';
import { Route, Routes } from 'react-router-dom';
import Montavimas from './pages/Montavimas';
import Projektavimas from './pages/Projektavimas';
import Kontaktai from './pages/Kontaktai';
import NotFound from './pages/NotFound';
import TechninePrieziura from './pages/TechninePrieziura';

function App() {
  return (
    <div className='App'>
      <Header />
      <Routes>
        <Route path='/' exact element={<AboutPage />} />
        <Route path='/montavimas' element={<Montavimas />} />
        <Route path='/techninePrieziura' element={<TechninePrieziura />} />
        <Route path='/kontaktai' element={<Kontaktai />} />
        <Route path='/projektavimas' element={<Projektavimas />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
