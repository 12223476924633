import React from 'react';

const Kontaktai = () => {
  return (
    <div class='flex-contacts'>
      <div class='contacts-img-holder'>
        <img class='contacts-img' src='montavimaskameru.jpg' alt='' />
        <img class='contacts-img' src='gatosusisiekite.jpg' alt='' />
      </div>
      <div class='contacts-info'>
        <div>
          <h1 class='contactsh1'>KONTAKTAI</h1>
        </div>
        <div>
          <i class='fa-solid fa-envelope'></i>
          <span class='contacts'> uabgato@gmail.com</span>
        </div>
        <div>
          <i class='fa-solid fa-phone'></i>
          <span class='contacts'> (8 676) 89172</span>
        </div>
        <div class='info'>
          <h3>Gato, UAB</h3>
          <h3>Baltijos g. 2-28, 48255 Kaunas</h3>
          <h3>Įmonės kodas: 302295221</h3>
        </div>
      </div>
    </div>
  );
};

export default Kontaktai;
