import React from 'react';
import { useNavigate } from 'react-router-dom';

const LocationLine = ({ location }) => {
  const Navigate = useNavigate();
  return (
    <div class='location-line'>
      <button
        class='location-line-button'
        onClick={() => {
          Navigate('/');
        }}>
        Apie mus
      </button>
      <i class='fa-solid fa-angles-right'></i>
      <button class='location-line-button'>{location}</button>
    </div>
  );
};

export default LocationLine;
