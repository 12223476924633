import React from 'react';
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const Navigate = useNavigate();
  return (
    <header>
      <img
        class='logo'
        src='gatologo.png'
        alt=''
        onClick={() => {
          Navigate('/');
        }}
      />
      <div class='ahref'>
        <div class='phone'>
          <i class='fa-solid fa-phone'></i>
          <span class='contacts'> (8 676) 89172</span>
        </div>
        <button
          class='about-link navigation'
          onClick={() => {
            Navigate('/');
          }}>
          Apie mus
        </button>
        <button
          class='contact-link navigation'
          onClick={() => {
            Navigate('/kontaktai');
          }}>
          Kontaktai
        </button>
      </div>
    </header>
  );
};

Header.propTypes = {};

export default Header;
