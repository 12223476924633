import React from 'react';
import LocationLine from './LocationLine';

const Paslaugos = ({ topic }) => {
  return (
    <div>
      <div class='text-area'>
        <LocationLine location={topic} />
        {topic === 'montavimas' && (
          <h3>
            <img class='media-image-size' src='montavimasgato.jpg' alt='' />
            <ul>
              <li>Patalpų apsaugos sistemos Vaizdo stebėjimo sistemos</li>
              <li>Priešgaisrinės apsaugos sistemos</li>
              <li>Praėjimo kontrolės sistemos</li>
              <li>Teritorijų apsaugos sistemos</li>
              <li>Kompiuteriniai, telefoniniai tinklai</li>
              <li>Automatika</li>
            </ul>
            <p>
              {' '}
              Šias sistemas įrengiame butuose,namuose, biuruose, restoranuose,
              mokyklose ir kituose pastatuose ar patalpose. Montavimo darbus
              atliekame tiek naujos, tiek senos statybos pastatuose, kurių
              plotas nuo 20 kv. m iki 20 000 kv. m.Vienu metu montavimo darbus
              galime atlikti 5 atskiruose pastatuose po 10 000 kv. m.
            </p>
            <p>
              Montuojame tik kokybišką įrangą, naudojame tik kokybiškus kabelius
              ir visas kitas papildomas medžiagas, todėl atliktiems montavimo
              darbams suteikiame nuo 3-jų metų, o įrangai – nuo 2-jų metų
              garantiją. Kiekvienas darbuotojas aprūpintas kokybiškais
              įrankiais, todėl atliekamas kokybiškas darbas yra dvigubai
              našesnis už paprasto darbuotojo.
            </p>
          </h3>
        )}
        {topic === 'techninė Priežiūra' && (
          <h3>
            <img
              class='media-image-size'
              src='technineprieziuragato.png'
              alt=''
            />
            <p>
              Kokybiškai atliekame garantinę ir pogarantinę techninę priežiūrą:
            </p>
            <ul>
              <li>Apsaugos sistemoms</li>
              <li>Vaizdo stebėjimo sistemoms</li>
              <li>Priešgaisrinės apsaugos sistemoms</li>
              <li>Kompiuteriniams, telefoniniams tinklams</li>
              <li>Automatikai</li>
              <li>Garso sistemoms</li>
              <li>Žaibosauga</li>
            </ul>

            <p>
              Šioms sistemoms techninę priežiūrą atliekame vieną arba du kartus
              per metus. Į techninės priežiūros darbus įeina:
            </p>
            <ul>
              <li>atsakingų žmonių mokymas,</li>
              <li>kaip naudotis sistemomis;</li>
              <li>sistemos būklės įvertinimas;</li>
              <li>sistemos gedimų šalinimas (jei tokių aptinkame);</li>
              <li>
                tolimesnės rekomendacijos dėl remonto, programavimo,
                perprogramavimo, naudojimosi sistemomis;
              </li>
              <li>gedimų registravimas 24 val. per parą.</li>
            </ul>

            <p>Esant poreikiui ar būtinybei:</p>
            <ul>
              <li>Mokome atsakingus žmones naudotis sistemomis;</li>
              <li>
                Pateikiame naujas vartotojo instrukcijas (aiškiai suprantamas,
                lietuvių kalba).
              </li>
            </ul>
          </h3>
        )}
        {topic === 'projektavimas' && (
          <h3>
            <img class='media-image-size' src='projektavimasgato.jpg' alt='' />
            <ul>
              <li>Apsaugos sistemos</li>
              <li>Vaizdo stebėjimo sistemos</li>
              <li>Priešgaisrinės apsaugos sistemos</li>
              <li>Kompiuteriniai, telefoniniai tinklai</li>
              <li>Automatika</li>
              <li>Garso sistemos</li>
              <li>Žaibosauga</li>
            </ul>
            <p>
              Šias sistemas kokybiškai projektuojame komercinės, gyvenamosios ir
              pramoninės paskirties pastatuose ir patalpose. Prieš atlikdami
              projektavimo darbus, įvertiname pastato ir pateiktų brėžinių
              neatitikimus, skirtumus. Projektavimą deriname kartu su kitomis
              projekto dalimis (jeigu to reikia).
            </p>
            <p>
              Projektuojame iš .dwg formatu pateiktų pastato planų. Taip pat
              projektus galime padaryti iš .pdf formato failų, popierinių
              brėžinių, skenuotų lapų, kalkių. Suprojektuotas sistemas
              pateikiame skaitmeniniu būdu (.pdf formatu) ir trimis
              atspausdintomis kopijomis.
            </p>
            <p>
              Jei reikia, atliekame projekto korekcijas iki visiško projekto
              įgyvendinimo. Konsultuojame visais projektavimo klausimais.
            </p>
          </h3>
        )}
      </div>
    </div>
  );
};

export default Paslaugos;
