import React from 'react';
import CardHolder from '../components/CardHolder';

const AboutPage = () => {
  return (
    <>
      <CardHolder />
    </>
  );
};

export default AboutPage;
