import React from 'react';
import { useNavigate } from 'react-router-dom';

const CardHolder = () => {
  const Navigate = useNavigate();
  return (
    <>
      <div class='about-page'>
        <main>
          <h1>Apie mus</h1>
          <h3>
            Apsaugos sistemos jūsų namams bei verslui! Siūlome įvairias
            signalizacijas, signalizacijos montavimo bei visas kitas susijusias
            paslaugas už jums prieinamą kainą!
          </h3>
        </main>
      </div>
      <div class='flex'>
        <div
          class='foto projectingCard'
          onClick={() => {
            Navigate('/projektavimas');
          }}>
          <div class='image'>
            <img class='img' src='projecting.jpg' alt='' />
          </div>
          <h4>Idėja</h4>
          <h1>Projektavimas</h1>
        </div>
        <div
          class='foto implementingCard'
          onClick={() => {
            Navigate('/montavimas');
          }}>
          <div class='image'>
            <img class='img' src='montavimas.jpg' alt='' />
          </div>
          <h4>Patirtis</h4>
          <h1>Montavimas</h1>
        </div>
        <div
          class='foto serviceCard'
          onClick={() => {
            Navigate('/techninePrieziura');
          }}>
          <div class='image'>
            <img class='img' src='technineprieziura.jpg' alt='' />
          </div>
          <h4>Profesionalumas</h4>
          <h1>Techninė priežiūra</h1>
        </div>
      </div>
    </>
  );
};

export default CardHolder;
